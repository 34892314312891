:root {
  --emo: cubic-bezier(.4, 0, .2, 1);
  --emo-in-out: cubic-bezier(.4, 0, .2, 1);
  --emo-out: cubic-bezier(0, .2, .2, 1);
}

.fadeIn {
  animation: 500ms fadeIn forwards;
}

.fadeOut {
  animation: 500ms fadeOut forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeUpIn {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}



.transitionScaleIn {
  transition-property: transform;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .4s;
  transform: scale(1);
}

.transitionScaleOut {
  transition-property: transform;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .4s;
  transform: scale(0);
}

.scaleInCenter {
  animation-timing-function: cubic-bezier(.4,0,.2,1);
  animation: 400ms scaleInCenter forwards;
  left: 50%;
  top: 50%;
}

@keyframes scaleInCenter {
  0% {
    transform: translateX(-50%) translateY(-50%) scale(0);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) scale(1);
  }
}

.scaleOutCenter {
  animation-timing-function: cubic-bezier(.4,0,.2,1);
  animation: 400ms scaleOutCenter forwards;
  left: 50%;
  top: 50%;
}

@keyframes scaleOutCenter {
  0% {
    transform: translateX(-50%) translateY(-50%) scale(1);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) scale(0);
  }
}

.scaleIn {
  animation-timing-function: cubic-bezier(.4,0,.2,1);
  animation: 400ms scaleIn forwards;
  /*transform: scale(1);*/
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.scaleOut {
  animation-timing-function: cubic-bezier(.4,0,.2,1);
  animation: 400ms scaleOut forwards;
  /*transform: scale(1);*/
}

@keyframes scaleOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.delay {
  animation-delay: .5s;
}

.scaleInUp {
  animation-timing-function: cubic-bezier(.4,0,.2,1);
  animation-name: scaleInUp;
  animation-duration: .8s;
  animation-direction: forwards;
  /*animation-delay: .5s;*/
  /*transform: scale(0);*/
}

@keyframes scaleInUp {
  from {
    transform: scale(0) translateY(200px);
  }
  to {
    transform: scale(1) translateY(0);
   }
}

.scaleOutDown {
  animation-timing-function: var(--emo-out);
  animation: 400ms scaleOutDown forwards;
  /*transform: scale(1);*/
}

@keyframes scaleOutDown {
  0% {
    transform: scale(1) translateY(0);
  }
  100% {
    transform: scale(0) translateY(128px);
  }
}

@keyframes flyUp {
  0% {
    bottom: -10%;
  }
  100% {
    bottom: 10%;
  }
}


@keyframes flyUp0 {
  0% {
    bottom: -50%;
  }
  100% {
    bottom: 0%;
  }
}

.pulse {
  animation: 5s pulse infinite;
  animation-timing-function: var(--emo);
}

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.fadePulse {
  animation: 1.5s fadePulse infinite;
  animation-timing-function: ease;
}

@keyframes fadePulse {
  0% {
    opacity: .1;
  }
  50% {
    opacity: .8;
  }
  100% {
    opacity: .1;
  }
}

.eqScale {
  animation: .5s eqScale infinite;
  animation-timing-function: var(--emo);
}

@keyframes eqScale {
  0% {
    transform: scale(1, 0);
  }
  25% {
    transform: scale(1, 1.1);
  }
  50% {
    transform: scale(1, .4);
  }
  75% {
    transform: scale(1, .7);
  }
  100% {
    transform: scale(1, 0);
  }
}

.flipIn {
  animation: 500ms flipIn forwards;
}

.flipOut {
  animation: 500ms flipOut forwards;
}

@keyframes flipOut {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(90deg);
  }
}

@keyframes flipIn {
  0% {
    transform: rotateY(90deg);
  }
  50% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

.slideInRight {
  animation: 500ms slideInRight forwards;
  animation-timing-function: var(--emo-out);
}

.slideOutRight {
  animation: 500ms slideOutRight forwards;
  animation-timing-function: var(--emo-out);
}

@keyframes slideInRight {
  0% {
    right: -480px;
  }
  100% {
    right: 0px;
  }
}

@keyframes slideOutRight {
  0% {
    right: 0px;
  }
  100% {
    right: -480px;
  }
}

.slideInBottom {
  animation: 500ms slideInBottom forwards;
  animation-timing-function: var(--emo-out);
}

@keyframes slideInBottom {
  0% {
    top: 100%;
  }
  100% {
    top: 64%;
  }
}

@keyframes randomFloat {
  0% {
    transform: rotateZ(45deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotateZ(90deg) translate3d(200px, 200px, 0px);;
  }
}

.randomFloat {
  animation: 40000ms randomFloat alternate;
  animation-iteration-count: infinite;
}

.delayShort {
  animation-delay: .5s;
}

.delay {
  animation-delay: 2s;
}

.spin {
  transition: transform;
  animation: spin 1.5s infinite;
  animation-timing-function: var(--emo);
  transform-style: preserve-3d;
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  50% {
    transform: rotateZ(180deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.flipper {
  transition: all 0.6s;
  animation: flip 4s infinite;
  animation-timing-function: var(--emo);
  transform-style: preserve-3d;
}

@keyframes flip {
  25% {
    transform: rotateY(180deg);
  }

  50% {
    transform: rotateZ(180deg);
  }

  75% {
    transform: rotateX(180deg);
  }
}