#avatarLabels .avatarLabelContainer {
	position: absolute;
	left: 0;
	top: 0;
	cursor: pointer;
	user-select: none;
	white-space: nowrap;
	text-shadow: 0px 8px 24px rgba(0, 0, 0, .2);
	/*mix-blend-mode: exclusion;*/
}

.avatarVideoContainer {
	width: 96px;
	height: 96px;
	border-radius: 100%;
	border-radius: 100%;
	position: absolute;
	bottom: 3rem;
	left: calc(-96px / 2 + 32px);
}

.avatarVideoContainer div {
	border-radius: 100%;
	background-color: transparent !important;
}

.avatarLabel {
	transition-property: transform, background-color, width, color;
	transition-duration: .2s;
	transition-timing-function: var(--emo-out);


	background: white;
	color: rgba(255,255,255, 0);
	border-radius: 64px;
	width: 64px;
	height: 64px;
	padding-top: 14px;
	padding-right: 12px;
	padding-left: 12px;
	/*height: 32rem;*/
	/*top: 0;*/
	/*position: absolute;*/
    /*transform: translate(-50%, 0);*/
    transform: scale(.25);
}

.avatarLabelContainer:hover .avatarLabel {
    transform: translate(0, -16px) scale(1);
    width: auto;
    /*height: auto;*/
	/*border-radius: 0px;*/
	background: black;
	color: white;
    /*transform: translate(-50%, 0) scale(1);*/
}

.audioIndicator {
	color: black;
	font-size: 40px;
}

.avatarLabelContainer:hover .audioIndicator {
	color: white;
	font-size: 24px;
	padding: 0 8px 4px 0px;
}